import React, { Fragment, useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import { useLocation } from '@reach/router'
import Footer from '../Footer/footer'
import Header from '../Header/header'
import HeaderMobile from '../Header/headerMobile'
import '../../scss/base.scss'
import '../../scss/plugins/bootstrap.scss'
import '../../fonts/Spinnaker/Spinnaker.css'
import '../../fonts/HendersonSans/Henderson.css'
import Amplify from 'aws-amplify'
import awsconfig from '../../aws-exports'

Amplify.configure(awsconfig)

const Layout = ({ children }) => {
  const intl = useIntl()
  const location = useLocation()
  const currentPath = location.pathname.replace(/\/$/, '')

  useEffect(() => {
    if (typeof window === 'undefined') return

    const handleRouteChange = () => {
      const newPath = window.location.pathname.replace(/\/$/, '')
      if (newPath !== currentPath) {
      }
    }

    const observer = new MutationObserver(handleRouteChange)
    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [currentPath])

  useEffect(() => {
    const isGamePage = currentPath === '/jogo-linha-do-tempo-lwart'

    if (isGamePage) {
      console.log('Estamos na página do jogo, removendo chatbot...')
      removeChatbot()
    } else {
      console.log('Carregando chatbot...')
      loadChatbot()
    }
  }, [currentPath])

  const removeChatbot = () => {
    const chatbotScript = document.querySelector('script[src*="globalbot.ai"]')
    if (chatbotScript) chatbotScript.remove()

    const chatbotFrame = document.getElementById('gbt-frame')
    if (chatbotFrame) chatbotFrame.remove()
  }

  const loadChatbot = () => {
    if (!document.querySelector('script[src*="globalbot.ai"]')) {
      const s1 = document.createElement('script')
      s1.async = true
      s1.src = 'https://globalbot.ai/init/1/62976724F5D9E6385CB1781A3D46E8A6-2D04-4CC7-9C53-703441DA6934'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      document.body.appendChild(s1)
    }
  }

  useEffect(() => {
    const chatbotFrame = document.getElementById('gbt-frame')

    if (chatbotFrame) {
      if (intl.locale === 'pt') {
        chatbotFrame.classList.add('d-block', 'chatbot')
        chatbotFrame.classList.remove('d-none')
      } else {
        chatbotFrame.classList.add('d-none')
        chatbotFrame.classList.remove('d-block', 'chatbot')
      }
    }
  }, [intl.locale])

  return (
    <Fragment>
      {typeof window !== 'undefined' && (
        <a className="top-link hide" href="#topo" id="myBtn">
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 6'><path d="M12 6H0l6-6z" /></svg>
        </a>
      )}
      <div>
        <HeaderMobile />
        <Header initialDarkBackground={false} />
        <main>{children}</main>
        <Footer />
      </div>
    </Fragment>
  )
}

export default Layout
